// PlotDetails
.plot-details {
  .leaflet-container {
    width: 100%;
    height: 280px;
  }
}

// PlotItem
.plot-item {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 60px;
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    background-color: map_get($theme-colors, 'light');
  }

  &.active {
    background-color: map_get($theme-colors, 'light');
  }
}

// PlotList
.plot-list {
  height: 400px;
  overflow-y: scroll;
}

// Plot input
.plot-small-input {
  height: 2.5em !important;
  font-size: 1em !important;
  color: #3485C9 !important;
}
.plot-input {
  font-size: 1.2em !important;
  color: #3485C9 !important;
}

// Plot error
.plot-error {
  padding: 2em;
  background-color: #ca1111;
  color: white;
}
