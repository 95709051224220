.hc_modal {
  background-color: rgba(0, 0, 0, .5);

  .actions {
    position: relative;
    right: -10px;
  }
}

.modal-header-comp {
  font-size: 22px;
  letter-spacing: 2.5px;
  line-height: 24px;
  margin-top: -20px;
  

  .line2:after {
    background-color: map_get($theme-colors, 'yellow');
    content: '';
    display: block;
    height: 4px;
    position: relative;
    top: 5px;
    width: 30px;
  }
}
