.header-action{
  .image {
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;

    @media screen and (min-width: 992px) {
      height: 280px;
    }
  }

  .content {
    position: relative;
    margin: -3rem 2rem 0 2rem;
    padding: 2rem;
    border-radius: 10px;

    @media screen and (min-width: 992px) {
      margin: -4rem 4rem 0 4rem;
      padding: 2rem;
    }

    .fa-chevron-left {
      position: absolute;
      font-size: 30px;
      @media screen and (min-width: 992px) {
        font-size: 50px;
      }
    }
    .action{
      margin: 0 30px;
      font-size: 30px;
      line-height: 35px;

      @media screen and (min-width: 992px) {
        margin: 0 100px;
        font-size: 50px;
        line-height: 50px;
      }
    }
    .description{
      margin: 0 30px;

      @media screen and (min-width: 992px) {
        margin: 0 100px;
      }
    }
  }
}
