// Pilot
.pilot {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 80px;
    text-align: center;
  }

  .line{
    width: 30px;
    height: 2px;
    background-color: map_get($theme-colors, 'info');
  }
}