@import 'abstracts/variables';

@import 'base/table';
@import 'base/typography';

@import 'components/buttons';
@import 'components/header-action';
@import 'components/input-with-icon';
@import 'components/switches';
@import 'components/sidebar';
@import 'components/quick-access';
@import 'components/menus';
@import 'components/lang-selector';
@import 'components/plots';
@import 'components/pilot';
@import 'components/modal';
@import 'components/welcome-block';
@import 'components/tabs';


@import '../../node_modules/bootstrap/scss/bootstrap';

@import 'layout/general';
@import 'layout/navigation';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/forms';
@import 'layout/page-synthesis';
@import 'layout/page-login';
@import 'layout/page-plot';
@import 'layout/params-sign';

@import 'theme/customs';

#webpack-dev-server-client-overlay-div {
  display: none !important;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}
