// Colors
$color__info: #839CA9;
$color__success: #71BF44;
$color__danger: #E63912;
$color__accent: #00CBFF;

$theme-colors: (
  'primary': $color__success,
  'secondary': #3485C9,
  'accent': $color__accent,
  'info': $color__info,
  'light': #F8F8F8,
  'grey' : #DCDCDC,
  'white': #FFFFFF,
  'success': $color__success,
  'danger': $color__danger,
  'yellow': #fc0,
);

// Borders
$border-large-width: 3px;

// Buttons
$btn-border-radius: 3px;
$btn-padding-x: 1.5rem;
$btn-padding-y: .9rem;

// Forms
$input-border-width: 2px;
$input-border-color: map_get($theme-colors, 'grey');;
$input-placeholder-color: $color__info;
$input-font-size : .9rem;
$input-line-height: 2;

// Tables
$table-color: map_get($theme-colors, 'info');
$table-dark-color: #FFFFFF;
$table-dark-bg: $color__info;
