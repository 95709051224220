#side-menu {

  text-align: center;
  
  @media screen and (min-width: 992px) {
    min-height: 100vh;
    padding-top: 150px;
  }

  a, button {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: transparent;

    &.active, &:hover {
      background-color: $color__accent;
    }

    @media screen and (min-width: 992px) {
      display: block;
    }
  }
}

#admin-menu, #culture-menu {
  a {
    color: map_get($theme-colors, 'info');
    display: block;
    font-weight: 700;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    padding: 10px 5px;

    &:hover {
      text-decoration: none;
    }

    &.active {
      color: map_get($theme-colors, 'accent');
    }
  }
}

#admin-menu {
  a {
    &:hover {
      background-color: map_get($theme-colors, 'light');
    }
  }
}

#culture-menu {
  a {
    &:hover {
      background-color: map_get($theme-colors, 'white');
    }
  }
  a.active {
    background-color: map_get($theme-colors, 'white');
    color: #839CA9;
  }
}