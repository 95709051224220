// # Typography rules
@font-face{
  font-family: 'Roboto';
  src: url('../Roboto-Regular.ttf')
}

@font-face{
  font-family: 'RobotoBold';
  src: url('../Roboto-Bold.ttf')
}

body {
  font-family: 'Roboto', sans-serif !important;
  font-size: .9rem !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.has-offset {
  font-size: 18px;
  line-height: 24px;
  position: relative;
  z-index: 1;

  &:after {
    content: attr(data-title);
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 600;
    color: map_get($theme-colors, 'grey');
    position: absolute;
    left: 0;
    bottom: 15px;
    z-index: -1;
  }
}