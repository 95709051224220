// # Forms
.form-control {
  border : none;
  border-bottom : $input-border-width solid $input-border-color;
  border-radius: 0;
  background-color: transparent;

  &:focus {
    background-color: transparent;
  }
}