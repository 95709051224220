.tabs-nav.nav.nav-pills {
  .nav-item {
    .btn.nav-link.btn-tabs {
      color: map_get($theme-colors, 'info');
      border-bottom: 2px solid transparent;
      background-color: transparent !important;

      &:hover, &.active {
        font-weight: 600;
        color: map_get($theme-colors, 'primary');
      }
      
      &.active {
        border-bottom: 2px solid map_get($theme-colors, 'primary');
      }
    }
  }
}