.hc-input-w-icon {
  .input-group-append {
    margin-left: 0;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
}
