#sidebar {
  padding-bottom: 35px;
  box-shadow: .5rem .1rem 1rem rgba(0,0,0,.15);
  z-index: 1;
  
  @media screen and (min-width: 992px) {
    min-height: 100vh;
    min-width: 420px;
    max-width: 500px;
  }

  .app-logo img {
    width: 250px;
  } 
}