#page-synthesis {
  h1 {
    color: #839CA9;
    text-transform: uppercase;
    font-size: 30px;
  }

  h2 {
    color: #71BF44;
    font-size: 15px;
    text-transform: uppercase;
    padding: 20px;
  }

  .averageContainer {
    background: #f8f8f8;
    .line-value {
      display: flex;
      flex-direction: 'row';
      align-items: center;
      padding: 0px 20px;
    }
    .averageImg {
      width: auto;
      height: 70px;
      padding: 10px;
    }
    
    .averageValue {
      color: #839CA9;
      font-size: 30px;
      padding: 10px;
    }
    
    .averageText {
      color: #839CA9;
      font-size: 15px;
      padding: 10px;
    }
  }
  
}

