main {
  flex: 1;
  position: relative;
  .cgu {
    position: absolute;
    bottom: 0;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-bottom: 15px;
    z-index: 1001;
  }
}

.page {
  @media screen and (min-width: 992px) {
    display: flex;

    .page-content {
      flex: 1;
      padding: 3rem;

      &.short {
        max-width: 450px;
      }
    }
  }
}

.signActive {
  background-color: white;
}

.map {
  cursor: pointer;
}

.mapLocation {
  fill: #6f8b99;
  box-sizing: border-box;
  border: none;
  outline:none;
}

.mapLocation:hover {
  fill: #88c765;
}

.mapLocation[aria-checked=true] {
  fill: #71c044;
}

.purplePins {
  background: #8000ff;
}

.pinsContainer {
  position: relative;
}

.pins {
  height: 20px;
  width: 20px;
  border-radius: 50% 50% 0 50%;
  transform: rotate(45deg);
  z-index: 0;
}

.swal-footer {
  text-align: center;
  .swal-button {
    background: map_get($theme-colors, 'accent');
  }
}

.pinsContainer span {
  z-index: 1;
  position: absolute;
  width: 20px;
  text-align: center;
  color: white;
  font-size: 5;
}

.orangePins {
  background: #ff8400;
}

.treeCheckbox {
  .rct-title {
    color: #839CA9 !important;
    text-transform: initial;
    font-weight: initial;
  }
  .rct-checkbox, .rct-collapse.rct-collapse-btn {
    color: black !important;
  }
  .rct-node-icon {
    display: none;
  }
}

.button-delete {
  background-color: map_get($theme-colors, 'danger') !important;
  border-color: map_get($theme-colors, 'danger') !important
}