.welcome-block {
  position: relative;
  overflow: visible;
  padding-top: 40px;

  .h1 {
    position: absolute;
    top: -25px;
    left: 0;
    font-size: 30px !important;
    letter-spacing: 10px;

    @media screen and (min-width: 1200px) {
      font-size: 45px !important;
    }
  }

  .username {
    font-size: 17px;

    @media screen and (min-width: 1200px) {
      font-size: 30px;
    }

    img {
      position: relative;
      top: -2px;

      @media screen and (max-width: 1199px) {
        width: 20px !important; // Fix user name overflow on small devices
      }
    }
  }
}
