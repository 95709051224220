.hc-switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 22px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      border-color: $color__success;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
      background-color: $color__success;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    border: 2px solid $color__danger;
    background-color: transparent;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      border-radius: 50%;
      left: 2px;
      bottom: 2px;
      background-color: $color__danger;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
}