.plot-map-container {
    margin: 15px;
    & > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
    }

    .plot-map-actions {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
    }

    .plot-map {
        position: relative;
        height: 400px;
        width: 100%;
    }
}

.plot-content {
    margin: 15px;
}
