 #page-login {
   header img {
    max-width: 244px;
   }

   .column {
    position: relative;
    flex: 1;
  }

  #block-npilot {
    background-color: rgba(255, 203, 5, .3);
    width: 350px;
    border-bottom: 5px solid #FFCB05;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      width: 250px;
      max-width: 100%;
    }
  }

  footer {
    @media screen and (min-width: 992px) {
     position: absolute;
     bottom: 0;
     left: 0;
     right: 0;
    }
  }
 }

