// # Theme overrides
img {
  max-width: 100%;
}

.btn {
  border-radius: 5px !important;
  &.btn-primary, &.btn-outline-primary:hover{
    color: theme-color('white');
  }

  &.btn-yellow, &.btn-outline-yellow:hover{
    border-color: theme-color('yellow');
    background-color: theme-color('yellow');
    color: theme-color('white');
  }
  &.btn-yellow:hover {
    border-color: #daae00;
    background-color: #daae00;
  }
  
  &.btn-white, &.btn-outline-white:hover {
    border-color: theme-color('primary');
    background-color: theme-color('primary');
    color: theme-color('white');
  }
  
  &.btn-white:hover {
    border-color: #64af38;
    background-color: #64af38;
  }

}

label, .label {
  width: 100%;
  color: theme-color('info');
  
  &:not(.checkbox-label) {
    color: theme-color('primary');
    text-transform: uppercase;
    font-weight: 600;
  }
}

select.form-control {
  border: 1px solid $input-border-color;
}

input[type="checkbox"] {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

// # Helpers
.has-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.has-bg-rgba {
  background-color: rgba($color: #000000, $alpha: .41);
}

.border-large {
  border-width: $border-large-width !important;
}

.has-input-white {
  .form-control {
    background-color: #FFFFFF;
  
    &:focus {
      background-color: #FFFFFF;
    }
  }
}

.export-bloc {
  @media screen and (min-width: 992px) {
    width: 30%;
  }
}
